// @flow

import { Card, Col, Row } from 'antd';

import Link from '../Link';
import React from "react";

const {Meta} = Card;

const WhyView = () => {

  return (
    <>
      <h2 level={2} className='text-center'>
        Why send letters online?
      </h2>
      <Row className='top-space-2x' gutter={24}>
        <Col xs={24} md={12} >
          <Card bordered={false} className='light'>
            <Meta
              title="You are abroad"
              description={<p className='ant-typography'>Are you on holiday or on a business trip and have to send an important letter? Finding a post office in a foreign country isn't always easy. Meanwhile, <b>sending a letter on PostSeal only takes 2 minutes!</b> All you need is internet access. Snail mail can be sent from anywhere in the world.</p>}
            />
          </Card>
        </Col>
        <Col xs={24} md={12} >
          <Card bordered={false} className='light'>
            <Meta
              title='You do not like calling customer service'
              description={<p className='ant-typography'>Would you like to claim a refund? Or would you like to set up direct debit with your bank? Cancel subscription of a service you haven't used in years? We live in the 21st century, but so many companies still rely on call centers or forms sent via mail, and do not allow submitting them online. Calling customer service is time consuming and sometimes quite expensive. You can submit your form on PostSeal. We will print it and send it for you.</p>}
            />
          </Card>
        </Col>
        <Col xs={24} md={12} >
          <Card bordered={false} className='light'>
            <Meta
              title='You have never sent real mail'
              description={
              <Row>
                <Col xs={24}>
                  <p className='ant-typography'>There is no shame in that, especially if you are younger. An <Link rel="noopener noreferrer" target='_blank' to='https://metro.co.uk/2017/09/18/43-of-millennials-have-never-used-snail-mail-6935647/'>article</Link> posted by metro.co.uk discusses an ipostparcels study finding that almost half of UK millennials have never sent a hand-written letter. Interestingly, 81% of those surveyed would be excited if they received a letter in the post, rather than a message on social media.
                    <br></br><br></br>
                    Would you like to surprise or maybe even prank your friend, partner or close relative, but are busy or feeling too stressed about sending snail mail? You can scan your hand-written letter or take a picture and send it via PostSeal. Not confident about your handwriting? You can still send a typed document saved as PDF file. Just make sure to put some thought into the content, your close one will love it!
                  </p>
                </Col>
                {
                  // <Col xs={{span: 14}} sm={{span: 11, offset: 2}} md={{span: 8, offset: 4}} lg={{span: 6, offset: 6}}>
                  //     <Img fluid={imgSrc} />
                  // </Col>
                }
              </Row>
            }/>
          </Card>
        </Col>
        <Col xs={24} md={12} >
          <Card bordered={false} className='light'>
            <Meta
              title='It is just... so convenient'
              description={<p className='ant-typography'>Don't have a printer, stamps, paper or envelopes? The closest post box is 10 or 20 minutes away? <b>PostSeal saves you time!</b> Fill in your PDF form or simply take a picture of your letter and upload it to us. Add return and destination addresses and we'll do the rest.</p>}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default WhyView;
